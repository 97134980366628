<template>
<div class="mainDiv">
  
  <div v-if="showSpinner" class="spinnerDiv">  
    <SpinnerComponent></SpinnerComponent>
  </div>
  

  <div v-if="showMessageDialog">
    <MessageDialog
      :displayDialog="showMessageDialog"
      :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
      @closeMessageDialog="closeMessageDialog"
    ></MessageDialog>
  </div>
  <div v-if="tokenPresent">
    <Dialog
      :screenDefinition = "screenDefinition"
      :dialogObject = "dialogObject"
      :selectedScope ="selectedScope"
      :isExternalEmbedDialog = true
      @closeDialog="closeDialog"
    ></Dialog>
  </div>
</div>  

</template>


<script>
import axios from "axios";
import SpinnerComponent from "./SpinnerComponent.vue";
import MessageDialog from "./MessageDialog.vue";
import Dialog from "./Dialog.vue";
import {setToLocalStorage} from "../store/localStorageInterface"
const uuid = require('uuid');
export default {
  
  components: {
    SpinnerComponent,
    MessageDialog,
    Dialog
  },
  data() {
    return {
      requestToken: '',
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      showSpinner: true,
      tokenPresent: false,
      screenDefinition :{},
      dialogObject: {},
      externalEmbedUrlOrigin:'',
      selectedScope: '',
    }
  },
  mounted() {
    // adding an eventlistener to listen for token from authenticated origin
    window.addEventListener('message', (event) => {
      // check the origin where we get the token from
      this.externalEmbedUrlOrigin = event.origin;
      const messageToken = event.data.token;
      if (messageToken) {
        // obtain the token from the event
          this.requestToken = messageToken;
        } 
    });
    this.$root.$on("update_session_value", (value) => {
      this.errorMessageShow = value;
      this.errorMessageObject = {
            errorMessageShow: this.errorMessageShow,
            error_code: 1800,
            error_uuid: uuid(),
      };
      this.showMessageDialog = value;
      this.showSpinner = false;
      this.tokenPresent = false;
    });
   
  },
  watch: {
    requestToken:{
      handler(newToken) {
        let reqBody = {
          externalEmbedUrlOrigin: this.externalEmbedUrlOrigin
        };
        axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL+'/getExternalEmbedDefinitions',
        data: reqBody,
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${newToken}` 
        },
      }).then((dialogDetails) => {
        this.screenDefinition['fieldDetails'] = dialogDetails.data.fieldDetails;
        this.screenDefinition['scopeDefinition'] =  dialogDetails.data.scopeDefinition;
        this.dialogObject = {
          "display"  : true,
          "title": "Add a Record",
          "viewId" : dialogDetails.data.viewDetails.id,
          "buttons" : ["Save", "Cancel"], 
          "viewType" : "workflow",
        };
        // for now the scope is limited to first scope key
        this.selectedScope = (dialogDetails.data.scopeDefinition.length > 0) ? dialogDetails.data.scopeDefinition[0]['key'] : '';
        // setup localstorage data for the validations 
        let parentUrl = (window.location != window.parent.location) ? document.referrer: document.location.href;
        setToLocalStorage('token', dialogDetails.data.token);
        setToLocalStorage('authType', 'externalEmbed');
        setToLocalStorage('userId', dialogDetails.data.userDetails['userId']);
        setToLocalStorage('userGroup', dialogDetails.data.userDetails['roleGroup']);
        setToLocalStorage('externalEmbedUrl', parentUrl);
        // setting up query params
        const searchURL = new URL(window.location);
        searchURL.searchParams.set('applicationId',  dialogDetails.data.applicationId);
        this.showSpinner = false
        this.tokenPresent = true
      }).catch((getDefinitionsError) => {
          // when error thrown by get embed definitions api:
          this.errorMessageShow = true;
          if (getDefinitionsError.response.status != 200) {
          this.errorMessageObject = {
            errorMessageShow: this.errorMessageShow,
            errorMessage: getDefinitionsError.response.data.message,
            error_code: getDefinitionsError.response.data.errorObject.error_code,
            error_uuid: getDefinitionsError.response.data.errorObject.error_uuid,
          };
          } else {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
          };
          } 
          this.showMessageDialog = true;
          this.showSpinner = false
        });
      },
      deep: true
    }
  },
  methods:{
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if(emitObject && emitObject.errorDisplayMessage){
        closeDialogFlag = emitObject.errorDisplayMessage
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    closeDialog(flag) {
      this.tokenPresent  = flag;
    },
  }
}
</script>
<style scoped>
.mainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
